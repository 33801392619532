<template>
  <v-container>
    <v-card class="mx-auto" color="green lighten-3" max-width="600">
      <v-card-title>
        <v-icon large left>
          mdi-file-key
        </v-icon>
        <span class="title font-weight-light">Datenschutzerklärung</span>
        <v-icon v-if="dppTermsChecked" color="green">mdi-check</v-icon>
        <v-spacer></v-spacer>
        <v-btn
          text
          v-if="dppTermsChecked"
          @click="showDppTerms = !showDppTerms"
        >
          anzeigen<v-icon>{{
            showDppTerms ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <div v-show="showDppTerms">
          <v-card-text v-html="dppTerms ? dppTerms.dppTermText : null">
            {{ dppTerms ? dppTerms.dppTermText : "" }}
          </v-card-text>

          <v-card-actions>
            <v-icon v-if="!dppTermsChecked" color="red">mdi-alert</v-icon>
            <v-checkbox
              v-model="dppTermsChecked"
              label="Ich habe die Datenschutzerklärung gelesen und akzeptiere sie."
              @change="
                () => {
                  setAcceptDppTerms(dppTermsChecked);
                  //dppTermsChecked ? (showDppTerms = false) : (showDppTerms = true);
                }
              "
            ></v-checkbox>
            <v-btn v-if="dppTermsChecked" @click="showDppTerms = false"
              >ausblenden</v-btn
            >
          </v-card-actions>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "DppTermsCard",

  data() {
    return {
      showDppTerms: false
    };
  },

  methods: {
    ...mapActions(["setAcceptDppTerms"])
  },

  mounted: function() {
    this.showDppTerms = !this.dppTermsChecked;
  },

  computed: {
    Legende() {
      return this.$store.state.Legende;
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    dppTerms: {
      get() {
        return this.$store.state.dppTerms;
      }
    },
    dppTermsChecked: {
      get() {
        return this.$store.state.dppTermsChecked;
      },
      set(value) {
        this.$store.commit("setDppTermsChecked", value);
      }
    }
  }
};
</script>
