<template>
  <v-list nav dense>
    <v-list-item-group active-class="red--text text--accent-4">
      <v-list-item
        to="/admin/registrations"
        @click="enableAdminAreaComponentVisibility('registrations')"
      >
        <v-list-item-icon>
          <v-icon>mdi-barcode</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Anmeldungen</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="User.admin"
        to="/admin/groups"
        @click="enableAdminAreaComponentVisibility('groups')"
      >
        <v-list-item-icon>
          <v-icon>mdi-calendar-month</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Gruppen</v-list-item-title>
      </v-list-item>

      <v-list-item
        to="/admin/users"
        @click="enableAdminAreaComponentVisibility('users')"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-multiple</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Teilnehmer</v-list-item-title>
      </v-list-item>

      <v-list-item
        to="/admin/achievements"
        @click="enableAdminAreaComponentVisibility('achievements')"
      >
        <v-list-item-icon>
          <v-icon>mdi-trophy</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Abzeichen</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="User.admin"
        to="/admin/statistics"
        @click="enableAdminAreaComponentVisibility('statistics')"
      >
        <v-list-item-icon>
          <v-icon>mdi-chart-areaspline</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Statistik</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="User.admin"
        to="/admin/localSettings"
        @click="enableAdminAreaComponentVisibility('localSettings')"
      >
        <v-list-item-icon>
          <v-icon>mdi-cellphone-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>lokale Einstellungen</v-list-item-title>
      </v-list-item>

      <v-list-item
        v-if="User.admin"
        to="/admin/organisationSettings"
        @click="enableAdminAreaComponentVisibility('organisationSettings')"
      >
        <v-list-item-icon>
          <v-icon>mdi-application-cog</v-icon>
        </v-list-item-icon>
        <v-list-item-title>Organisationseinstellungen</v-list-item-title>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "AdminAreaMenu",

  components: {},

  data() {
    return {
      admins: [
        ["Management", "mdi-account-multiple-outline"],
        ["Settings", "mdi-cog-outline"]
      ]
    };
  },

  methods: {
    ...mapActions(["enableAdminAreaComponentVisibility"])
  },

  mounted: function() {},

  watch: {
    $route(to) {
      this.enableAdminAreaComponentVisibility(to.params.component);
    }
  },

  computed: {
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    }
  }
};
</script>
