import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: "",
    isLoading: false,
    loading: {
      cardPairingButton: false,
      dtTerminalLoadAllowedUsersForDate: false,
      dtTerminalLoadAllowedUsersForExistingCoursesForDate: false,
      dtTerminalLoadAllowedUsersAll: false,
      dtTerminalShutdown: false
    },
    loginFailed: "",
    showError: {
      visible: false,
      message: ""
    },
    componentVisibility: {
      extendedUserData: false,
      registerForm: false,
      termsCard: false,
      dppTermsCard: false,
      infoCard: false,
      personDetails: false,
      registrationDetails: false,
      adminArea: false,
      adminAreaComponents: {
        registrations: false,
        groups: false,
        users: false,
        achievements: false,
        statistics: false,
        localSettings: false,
        organisationSettings: false
      },
      adminDialog: false,
      showTrainingEditingDialog: false,
      showUserEditingDialog: false,
      showUserParticipationsDialog: false,
      showRegistrationEditingDialog: false,
      showGroupsBulkDialog: false
    },
    email: null,
    user: {
      id: null,
      name: "",
      firstName: null,
      birthdate: null,
      email: null,
      lane: null,
      organisation: null,
      membershipNr: null,
      status: 0,
      token: "",
      locked: false,
      notes: ""
    },
    adminArea: {
      courseDetails: {
        date: null,
        infotext: null,
        lane: null,
        laneDescription: null,
        maxParticipants: 10,
        organisation: null,
        occurenceTime: "17:00",
        occurenceTimeText: "Wann musst du am Schwimmbad sein?",
        startTime: "17:10",
        startTimeText: "Wann beginnt das Training?",
        trainingTimeInMinutes: "60",
        trainingTimeText: "Wie lange geht das Training?",
        endTime: "18:00",
        endTimeText: "Wann muss ich das Schwimmbadgebäude verlassen haben?",
        registrationPossible: true
      },
      userDetails: {},
      registrationDetails: {},
      date: {},
      participantsStatistics: [],
      participantsTotals: {},
      allUsers: [],
      statistics: {
        startDate: new Date(Date.UTC(new Date().getFullYear(), 0, 1))
          .toJSON()
          .slice(0, 10),
        endDate: new Date().toJSON().slice(0, 10),
        participation: {
          byLanes: [],
          byLanesPerDay: []
        },
        badgeAchievements: {
          badges: [],
          achievements: []
        }
      }
    },
    course: [],
    coursesOfDay: [],
    participantList: [],
    terms: "",
    termsChecked: false,
    dppTerms: "",
    dppTermsChecked: false,
    chosenFile: null,
    Legende: {
      1: [
        "ist noch nicht angemeldet",
        "light-blue lighten-4",
        "verbindlich anmelden",
        "blue"
      ],
      2: ["ist angemeldet", "teal accent-3", "abmelden", "red"],
      3: [
        "befindet sich auf Warteliste",
        "orange lighten-2",
        "abmelden",
        "red"
      ],
      4: [
        "ist nicht angemeldet",
        "orange lighten-2",
        "Anmeldung derzeit nicht möglich",
        "red"
      ],
      5: [
        "kann nicht angemeldet werden",
        "orange lighten-2",
        "Anmeldung nicht möglich",
        "red"
      ],
      6: ["Aktuell kein Training verfügbar"]
    }
  },
  mutations: {
    setToken(state, newToken) {
      state.token = newToken;
    },
    setLoadingYes(state) {
      state.isLoading = true;
    },
    setLoadingNo(state) {
      state.isLoading = false;
    },
    setLoadingCardPairingBtn(state, value) {
      state.loading.cardPairingButton = value;
    },
    setLoadingDtTerminalLoadAllowedUsersForDateBtn(state, value) {
      state.loading.dtTerminalLoadAllowedUsersForDate = value;
    },
    setLoadingDtTerminalLoadAllowedUsersForExistingCoursesForDateBtn(
      state,
      value
    ) {
      state.loading.dtTerminalLoadAllowedUsersForExistingCoursesForDate = value;
    },
    setLoadingDtTerminalLoadAllowedUsersAllBtn(state, value) {
      state.loading.dtTerminalLoadAllowedUsersAll = value;
    },
    setLoadingDtTerminalShutdownBtn(state, value) {
      state.loading.dtTerminalShutdown = value;
    },
    setComponentVisibility(state, value) {
      state.componentVisibility = value;
    },
    resetAdminAreaComponentsVisibility(state) {
      state.componentVisibility.adminAreaComponents.registrations = false;
      state.componentVisibility.adminAreaComponents.groups = false;
      state.componentVisibility.adminAreaComponents.users = false;
      state.componentVisibility.adminAreaComponents.achievements = false;
      state.componentVisibility.adminAreaComponents.statistics = false;
      state.componentVisibility.adminAreaComponents.localSettings = false;
      state.componentVisibility.adminAreaComponents.organisationSettings = false;
    },
    enableAdminAreaComponent(state, visibleComponent) {
      state.componentVisibility.adminAreaComponents[visibleComponent] = true;
    },
    setUser(state, userData) {
      state.user = userData;
    },
    setCourse(state, course) {
      state.course = course;
    },
    setChosenFile(state, file) {
      state.chosenFile = file;
    },
    setTerms(state, terms) {
      state.terms = terms;
    },
    setDppTerms(state, dppTerms) {
      state.dppTerms = dppTerms;
    },
    setEmail(state, email) {
      state.email = email;
      state.componentVisibility.extendedUserData = email ? false : true;
    },
    setParticipantList(state, list) {
      state.participantList = list;
    },
    setAdminAreaDate(state, date) {
      state.adminArea.date = date;
    },
    setCoursesOfDay(state, list) {
      state.coursesOfDay = list;
    },
    setTermsChecked(state, isAccepted) {
      state.termsChecked = isAccepted;
    },
    setDppTermsChecked(state, isAccepted) {
      state.dppTermsChecked = isAccepted;
    },
    setAdminAreaAllUsers(state, userlist) {
      state.adminArea.allUsers = userlist;
    },
    setAdminAreaStatisticsStartDate(state, date) {
      state.adminArea.statistics.startDate = date;
    },
    setAdminAreaStatisticsEndDate(state, date) {
      state.adminArea.statistics.endDate = date;
    },
    setAdminAreaStatisticsParticipationByLanes(state, statistic) {
      state.adminArea.statistics.participation.byLanes = statistic;
    },
    setAdminAreaStatisticsParticipationByLanesPerDay(state, statistic) {
      state.adminArea.statistics.participation.byLanesPerDay = statistic;
    },
    setAdminAreaStatisticsBadgeAchievements(state, statistic) {
      state.adminArea.statistics.badgeAchievements = statistic;
    },
    approveError(state) {
      state.showError.visible = false;
    },
    setError(state, { errorMessage, e }) {
      state.showError.visible = true;
      state.showError.message = errorMessage;
      if (e.response) state.showError.message += ` ${e.response.data}`;
      state.showError.message += ` ${e}`;
    },
    updateAdminAreaParticipantsStatistics(state) {
      // compute a set of lanes
      const filterLanes = arr =>
        arr.reduce(
          (unique, item) =>
            unique.includes(item.lane) ? unique : [...unique, item.lane],
          []
        );
      let lanes = filterLanes(state.participantList);
      // count all participants which are registered and waiting
      const countAllOccurrences = (arr, val) =>
        arr.reduce((a, v) => (v.lane === val && v.isRegistered ? a + 1 : a), 0);
      // count all participants which are not waiting
      const countRegisteredOccurrences = (arr, val) =>
        arr.reduce(
          (a, v) =>
            v.lane === val && v.isRegistered && !v.waiting ? a + 1 : a,
          0
        );
      // count all participants which are present
      const countPresentOccurrences = (arr, val) =>
        arr.reduce((a, v) => (v.lane === val && v.present ? a + 1 : a), 0);
      // find course information
      const findCourseInformation = laneName =>
        state.coursesOfDay.find(course => course.lane == laneName);

      let statistics = [];
      lanes.forEach(element => {
        let courseInfo = findCourseInformation(element);
        let maxP = 0;
        if (courseInfo) maxP = courseInfo.maxParticipants;
        let stat = {
          lane: element,
          all: countAllOccurrences(state.participantList, element),
          registered: countRegisteredOccurrences(
            state.participantList,
            element
          ),
          present: countPresentOccurrences(state.participantList, element),
          maxParticipants: maxP
        };
        statistics.push(stat);
      });
      state.adminArea.participantsStatistics = statistics;
      //update totals as well
      const updateAdminAreaParticipantsTotals = state => {
        state.adminArea.participantsTotals = {
          all: 0,
          maxParticipants: 0,
          registered: 0,
          present: 0
        };
        state.adminArea.participantsStatistics.forEach(element => {
          state.adminArea.participantsTotals.all += element.all;
          state.adminArea.participantsTotals.maxParticipants +=
            element.maxParticipants;
          state.adminArea.participantsTotals.registered += element.registered;
          state.adminArea.participantsTotals.present += element.present;
        });
      };
      updateAdminAreaParticipantsTotals(state);
    },
    setAdminAreaRegistrationDetails(state, userdata) {
      let data = {};
      if (userdata) {
        data = userdata;
      }
      state.adminArea.registrationDetails = data;
    },
    setAdminAreaUserDetails(state, userdata) {
      let data = {};
      if (userdata) {
        data = userdata;
      }
      state.adminArea.userDetails = data;
    },
    setAdminAreaUserDetailsRfidCardUid(state, rfidCardUid) {
      state.adminArea.userDetails.rfidCardUid = rfidCardUid;
    },
    setAdminAreaCourseDetails(state, coursedata) {
      let data = {
        date: null,
        infotext: null,
        lane: null,
        laneDescription: null,
        maxParticipants: 10,
        organisation: state.user.organisation,
        occurenceTime: "17:00",
        occurenceTimeText: "Wann musst du am Schwimmbad sein?",
        startTime: "17:10",
        startTimeText: "Wann beginnt das Training?",
        trainingTimeInMinutes: "60",
        trainingTimeText: "Wie lange geht das Training?",
        endTime: "18:00",
        endTimeText: "Wann muss ich das Schwimmbadgebäude verlassen haben?",
        registrationPossible: true,
        registrationStartsAt: null,
        registrationStartsAtTime: null
      };
      if (coursedata) {
        data = coursedata;
      }
      state.adminArea.courseDetails = data;
    },
    updateStatus(state) {
      // reset component Visibility
      state.componentVisibility.extendedUserData = false;
      state.componentVisibility.registerForm = false;
      state.componentVisibility.termsCard = true;
      state.componentVisibility.dppTermsCard = true;
      state.componentVisibility.infoCard = false;
      state.componentVisibility.personDetails = false;
      state.componentVisibility.registrationDetails = false;
      state.componentVisibility.adminArea = false;
      state.componentVisibility.adminDialog = false;
      state.componentVisibility.showTrainingEditingDialog = false;
      state.componentVisibility.showUserEditingDialog = false;
      state.componentVisibility.showUserParticipationsDialog = false;
      state.componentVisibility.showRegistrationEditingDialog = false;
      // compute user status
      if (state.course && state.course.length > 0) {
        state.componentVisibility.extendedUserData = state.email ? false : true;
        state.componentVisibility.personDetails = true;
        state.componentVisibility.registrationDetails = true;
        if (state.user.prepayedOption) {
          state.componentVisibility.infoCard = true;
        }
        if (state.user.locked) {
          state.user.status = 5;
          state.componentVisibility.registrationDetails = false;
          state.componentVisibility.infoCard = true;
        }
      } else {
        // no training available
        state.user.status = 6;
        state.componentVisibility.personDetails = true;
        state.componentVisibility.registrationDetails = false;
        state.componentVisibility.infoCard = true;
      }
      if (!(state.user.admin == null) || !(state.user.trainer == null)) {
        state.componentVisibility.adminArea = true;
      }
      if (state.user.infoMessage) {
        state.componentVisibility.infoCard = true;
      }
      // compute Terms acceptance / checked
      if (
        state.user &&
        state.terms &&
        state.user.termsAcceptDate &&
        state.terms.updatedAt
      ) {
        state.termsChecked = state.user.termsAcceptDate > state.terms.updatedAt;
      }
      // compute DppTerms acceptance / checked
      if (
        state.user &&
        state.dppTerms &&
        state.user.dppTermsAcceptDate &&
        state.dppTerms.updatedAt
      ) {
        state.dppTermsChecked =
          state.user.dppTermsAcceptDate > state.dppTerms.updatedAt;
      }

      state.componentVisibility.registrationDetails =
        state.componentVisibility.registrationDetails &&
        state.termsChecked &&
        state.dppTermsChecked;
      state.componentVisibility.personDetails = state.dppTermsChecked;
    },
    setLoginFailed(state, isFailed) {
      state.loginFailed = "";
      if (isFailed) {
        state.loginFailed = "Anmeldung fehlgeschlagen";
      }
    }
  },
  getters: {
    getAdminDate: state => () => {
      return state.adminArea.date;
    }
  },
  actions: {
    async loadUser({ commit }, [token, rememberCode]) {
      commit("setLoadingYes");
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/find?token=` + token
        )
        .catch(e => {
          console.log(e);
          
          if (e.message == "Network Error") {
            commit("setError", {
              errorMessage: "Es besteht ein Problem mit dem Server. Bitte versuchen Sie es später erneut oder wenden Sie sich an Ihren Ansprechpartner.",
              e: e
            });
          }
          throw new Error("LoginFailed");
        })
        .then(response => {
          commit("setUser", response.data);
          commit("setEmail", response.data.email);
          // console.log(`User:` + JSON.stringify(response.data));

          // remember Code
          if (rememberCode) {
            localStorage.setItem("digitrain-user", response.data.token);
          }

          axios.defaults.headers.common["dt_token"] = response.data.token;
          return axios.get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/terms`);
        })
        .then(response => {
          commit("setTerms", response.data);
          // console.log(`Terms:` + JSON.stringify(response.data));
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/dppTerms/`
          );
        })
        .then(response => {
          commit("setDppTerms", response.data);
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/myCourses`
          );
        })
        .then(response => {
          if (response) {
            commit("setCourse", response.data);
          }
          commit("updateStatus");
          commit("setLoadingNo");
        })
        .catch(e => {
          if (e.message == "LoginFailed") {
            console.log("Loading user failed");
            //// Show now technical error message to users
            // commit("setError", {
            //   errorMessage: "Anmeldung fehlgeschlagen.",
            //   e: e
            // });
            commit("setUser", {});
            commit("setLoginFailed", true);
            commit("setLoadingNo");
          } else {
            console.log("Loading partially failed");
            commit("setCourse", null);
            commit("updateStatus");
            commit("setLoadingNo");
          }
        });
    },
    async loadCourses({ commit }) {
      commit("setLoadingYes");
      axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/terms`)
        .then(response => {
          commit("setTerms", response.data);
          // console.log(`Terms:` + JSON.stringify(response.data));
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/dppTerms/`
          );
        })
        .then(response => {
          commit("setDppTerms", response.data);
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/myCourses`
          );
        })
        .then(response => {
          if (response) {
            commit("setCourse", response.data);
          }
          commit("updateStatus");
          commit("setLoadingNo");
        });
    },
    async register({ commit }, courseId) {
      commit("setLoadingYes");
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings/register/${courseId}`
        )
        .then(() => {
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/myCourses`
          );
        })
        .then(response => {
          if (response) {
            commit("setCourse", response.data);
          }
          commit("updateStatus");
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Register failed");
          console.log(e);
          commit("setError", {
            errorMessage: "Anmeldung fehlgeschlagen.",
            e: e
          });
          commit("setUser", {});
          commit("setLoadingNo");
        });
    },
    async unregister({ commit }, courseId) {
      commit("setLoadingYes");
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings/unregister/${courseId}`
        )
        .then(() => {
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/myCourses`
          );
        })
        .then(response => {
          if (response) {
            commit("setCourse", response.data);
          }
          commit("updateStatus");
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Unregister failed");
          console.log(e);
          commit("setError", {
            errorMessage: "Abmeldung fehlgeschlagen.",
            e: e
          });
          commit("setUser", {});
          commit("setLoadingNo");
        });
    },
    async getUserById({ commit }, id) {
      return axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/${id}`)
        .then(response => {
          return response;
        })
        .catch(e => {
          console.log("Getting User failed");
          commit("setError", {
            errorMessage: "Laden von Personendaten fehlgeschlagen.",
            e: e
          });
        });
    },
    async setEmail({ commit }, [email, phoneNumber]) {
      commit("setLoadingYes");
      return axios
        .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/email`, {
          email: email,
          phoneNumber: phoneNumber
        })
        .then(response => {
          commit("setUser", response.data);
          commit("setEmail", response.data.email);
          commit("updateStatus");
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Unregister failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Ändern der E-Mail-Adresse fehlgeschlagen",
            e: e
          });
        });
    },
    async sendMessageToUser({ commit }, messageDetails) {
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/email/sentMessageToUser`,
          {
            messageToUser: messageDetails.messageToUser,
            recipientId: messageDetails.recipientId,
            recipientAddress: messageDetails.recipientAddress,
            recipientName: messageDetails.recipientName,
            replyToAddress: messageDetails.replyToAddress
          }
        )
        .catch(e => {
          commit("setError", {
            errorMessage: "Senden der Nachricht fehlgeschlagen",
            e: e
          });
        });
    },
    async sendTokenToUser({ commit }, messageDetails) {
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/email/sentTokenToUser`,
          {
            recipientId: messageDetails.recipientId
          }
        )
        .catch(e => {
          commit("setError", {
            errorMessage: "Senden der Nachricht fehlgeschlagen",
            e: e
          });
        });
    },
    async setUserRfidCardUid({ commit, dispatch }) {
      dispatch("setLoadingCardPairingBtnOn");
      let localIp = await localStorage.getItem("localIp");
      let adminToken = await localStorage.getItem("adminToken");
      if (adminToken && localIp) {
        return axios
          .post(`https://${localIp}/register`, "", {
            headers: { dt_token: adminToken }
          })
          .then(response => {
            commit("setAdminAreaUserDetailsRfidCardUid", response.data);
            dispatch("setLoadingCardPairingBtnOff");
          })
          .catch(e => {
            console.log("Updating user failed");
            console.log(e);
            dispatch("setLoadingCardPairingBtnOff");
            commit("setError", {
              errorMessage: "Koppeln fehlgeschlagen.",
              e: e
            });
          });
      } else {
        dispatch("setLoadingCardPairingBtnOff");
        commit("setError", {
          errorMessage: "Konfiguration für RFID-Scanner nicht gepflegt."
        });
      }
    },
    async triggerDigitrainTerminalToLoadAllRegisteredUsers({ commit }, date) {
      commit("setLoadingDtTerminalLoadAllowedUsersForDateBtn", true);
      let localIp = await localStorage.getItem("localIp");
      let adminToken = await localStorage.getItem("adminToken");
      console.log(localIp);
      if (adminToken && localIp && date) {
        console.log(localIp);
        return axios
          .get(`https://${localIp}/allowedList/load/${date}`, {
            headers: { dt_token: adminToken }
          })
          .then(() => {
            commit("setLoadingDtTerminalLoadAllowedUsersForDateBtn", false);
          })
          .catch(e => {
            console.log("Loading registered Users failed");
            console.log(e);
            commit("setLoadingDtTerminalLoadAllowedUsersForDateBtn", false);
            commit("setError", {
              errorMessage: "Laden der angemeldeten User fehlgeschlagen.",
              e: e
            });
          });
      } else {
        commit("setLoadingDtTerminalLoadAllowedUsersForDateBtn", false);
        commit("setError", {
          errorMessage:
            "Konfiguration für RFID-Scanner nicht gepflegt oder ungültiges Datum."
        });
      }
    },
    async triggerDigitrainTerminalToLoadAllowedUsersForExistingCoursesForDate(
      { commit },
      date
    ) {
      commit(
        "setLoadingDtTerminalLoadAllowedUsersForExistingCoursesForDateBtn",
        true
      );
      let localIp = await localStorage.getItem("localIp");
      let adminToken = await localStorage.getItem("adminToken");
      if (adminToken && localIp && date) {
        return axios
          .get(
            `https://${localIp}/allowedList/loadForExistingCourses/${date}`,
            {
              headers: { dt_token: adminToken }
            }
          )
          .then(() => {
            commit(
              "setLoadingDtTerminalLoadAllowedUsersForExistingCoursesForDateBtn",
              false
            );
          })
          .catch(e => {
            console.log("Loading Users for existing courses failed");
            console.log(e);
            commit(
              "setLoadingDtTerminalLoadAllowedUsersForExistingCoursesForDateBtn",
              false
            );
            commit("setError", {
              errorMessage:
                "Laden der User für existierende Kurse fehlgeschlagen.",
              e: e
            });
          });
      } else {
        commit(
          "setLoadingDtTerminalLoadAllowedUsersForExistingCoursesForDateBtn",
          false
        );
        commit("setError", {
          errorMessage:
            "Konfiguration für RFID-Scanner nicht gepflegt oder ungültiges Datum."
        });
      }
    },
    async triggerDigitrainTerminalToLoadAllUsers({ commit }) {
      commit("setLoadingDtTerminalLoadAllowedUsersAllBtn", true);
      let localIp = await localStorage.getItem("localIp");
      let adminToken = await localStorage.getItem("adminToken");
      if (adminToken && localIp) {
        return axios
          .get(`https://${localIp}/allowedList/loadAll`, {
            headers: { dt_token: adminToken }
          })
          .then(() => {
            commit("setLoadingDtTerminalLoadAllowedUsersAllBtn", false);
          })
          .catch(e => {
            console.log("Loading all Users failed");
            console.log(e);
            commit("setLoadingDtTerminalLoadAllowedUsersAllBtn", false);
            commit("setError", {
              errorMessage: "Alle User laden fehlgeschlagen.",
              e: e
            });
          });
      } else {
        commit("setLoadingDtTerminalLoadAllowedUsersAllBtn", false);
        commit("setError", {
          errorMessage: "Konfiguration für RFID-Scanner nicht gepflegt."
        });
      }
    },
    async triggerDigitrainTerminalToShutdown({ commit }) {
      commit("setLoadingDtTerminalShutdownBtn", true);
      let localIp = await localStorage.getItem("localIp");
      let adminToken = await localStorage.getItem("adminToken");
      if (adminToken && localIp) {
        return axios
          .get(`https://${localIp}/shutdown`, {
            headers: { dt_token: adminToken }
          })
          .then(() => {
            commit("setLoadingDtTerminalShutdownBtn", false);
          })
          .catch(e => {
            console.log("Shutting down Terminal failed");
            console.log(e);
            commit("setLoadingDtTerminalShutdownBtn", false);
            commit("setError", {
              errorMessage:
                "Herunterfahren von Digitrain-Terminal fehlgeschlagen.",
              e: e
            });
          });
      } else {
        commit("setLoadingDtTerminalShutdownBtn", false);
        commit("setError", {
          errorMessage: "Konfiguration für Digitrain-Terminal nicht gepflegt."
        });
      }
    },
    async setAcceptTerms({ commit }, isAccepted) {
      commit("setLoadingYes");
      return axios
        .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/acceptTerms`, {
          termsAccepted: isAccepted
        })
        .then(response => {
          commit("setUser", response.data);
          commit("setEmail", response.data.email);
          commit("updateStatus");
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Accepting terms failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          commit("setLoadingNo");
        });
    },
    async setAcceptDppTerms({ commit }, isAccepted) {
      commit("setLoadingYes");
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/acceptDppTerms`,
          {
            dppTermsAccepted: isAccepted
          }
        )
        .then(response => {
          commit("setUser", response.data);
          commit("setEmail", response.data.email);
          commit("updateStatus");
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Accepting DppTerms failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          commit("setLoadingNo");
        });
    },
    async setTerms({ commit }, terms) {
      commit("setLoadingYes");
      return axios
        .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/terms`, {
          termsText: terms
        })
        .then(() => {
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Updating terms failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          commit("setLoadingNo");
        });
    },
    async setDppTerms({ commit }, terms) {
      commit("setLoadingYes");
      return axios
        .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/dppTerms`, {
          dppTermText: terms
        })
        .then(() => {
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Updating DppTerms failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          commit("setLoadingNo");
        });
    },
    async setDateForAdministration({ commit }, date) {
      if (!date) {
        return;
      }
      commit("setAdminAreaDate", date);
      commit("setLoadingYes");
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/participants/${date}`
        )
        .then(response => {
          commit("setParticipantList", response.data);
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Loading Participants failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          commit("setParticipantList", []);
          commit("updateAdminAreaParticipantsStatistics");
          commit("setLoadingNo");
        })
        .then(() => {
          commit("setLoadingYes");
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/all/${date}`
          );
        })
        .then(response => {
          commit("setCoursesOfDay", response.data);
          commit("updateAdminAreaParticipantsStatistics");
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("Loading Courses of Day failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          commit("setCoursesOfDay", []);
          commit("updateAdminAreaParticipantsStatistics");
          commit("setLoadingNo");
        });
    },
    async updateAdminArea({ getters }) {
      this.dispatch("setDateForAdministration", getters.getAdminDate());
    },
    async loadAllUsers({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users`)
        .then(response => {
          commit("setAdminAreaAllUsers", response.data);
        })
        .catch(e => {
          console.log("Loading all Users failed");
          console.log(e);
          commit("setError", {
            errorMessage: "Laden von Usern fehlgeschlagen.",
            e: e
          });
        });
    },
    async downloadAllUsersAsCsv({ commit }) {
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/dataExport/allUsers?format=csv`,
          { responseType: "blob" }
        )
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", response.headers.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(e => {
          console.log("Loading all Users failed");
          console.log(e);
          commit("setError", {
            errorMessage: "Laden von Usern fehlgeschlagen.",
            e: e
          });
        });
    },
    async downloadRegistrationsForDate({ commit }, [date1, date2, format]) {
      return axios
        .get(
          `${
            process.env.VUE_APP_DT_SERVICE_HOST
          }/api/dataExport/registrations?format=${format}${
            date1 ? "&startDate=" + date1 : ""
          }${date2 ? "&endDate=" + date2 : ""}`,
          { responseType: "blob" }
        )
        .then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", response.headers.filename);
          document.body.appendChild(fileLink);

          fileLink.click();
        })
        .catch(e => {
          console.log("Loading all Users failed");
          console.log(e);
          commit("setError", {
            errorMessage: "Laden von Usern fehlgeschlagen.",
            e: e
          });
        });
    },
    async getStatisticsParticipationByLanes({ commit }, [date1, date2]) {
      return axios
        .get(
          `${
            process.env.VUE_APP_DT_SERVICE_HOST
          }/api/statistics/participation/byLanes?${
            date1 ? "&startDate=" + date1 : ""
          }${date2 ? "&endDate=" + date2 : ""}`
        )
        .then(response => {
          return commit(
            "setAdminAreaStatisticsParticipationByLanes",
            response.data
          );
        })
        .catch(e => {
          console.log(e);
          commit("setError", {
            errorMessage: "Laden von Gruppenstatistik fehlgeschlagen.",
            e: e
          });
        });
    },
    async getStatisticsParticipationByLanesPerDay({ commit }, [date1, date2]) {
      return axios
        .get(
          `${
            process.env.VUE_APP_DT_SERVICE_HOST
          }/api/statistics/participation/byLanesPerDay?${
            date1 ? "&startDate=" + date1 : ""
          }${date2 ? "&endDate=" + date2 : ""}`
        )
        .then(response => {
          return commit(
            "setAdminAreaStatisticsParticipationByLanesPerDay",
            response.data
          );
        })
        .catch(e => {
          console.log(e);
          commit("setError", {
            errorMessage: "Laden von Gruppenstatistik fehlgeschlagen.",
            e: e
          });
        });
    },
    async getStatisticsBadgeAchievments({ commit }, [date1, date2]) {
      return axios
        .get(
          `${
            process.env.VUE_APP_DT_SERVICE_HOST
          }/api/statistics/badgeAchievments?${
            date1 ? "&startDate=" + date1 : ""
          }${date2 ? "&endDate=" + date2 : ""}`
        )
        .then(response => {
          return commit(
            "setAdminAreaStatisticsBadgeAchievements",
            response.data
          );
        })
        .catch(e => {
          console.log(e);
          commit("setError", {
            errorMessage: "Laden von Abzeichen-Statistik fehlgeschlagen.",
            e: e
          });
        });
    },
    async updateParticipantsPresence({ commit }, participant) {
      commit("setLoadingYes");
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings/participantsPresence`,
          {
            usersTrainingData: participant
          }
        )
        .then(() => {
          commit("setLoadingNo");
        })
        .catch(e => {
          console.log("updateParticipant failed");
          console.log(e);
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
          this.dispatch("setDateForAdministration", this.state.adminArea.date);
          commit("setLoadingNo");
        });
    },
    async deleteUser({ commit, dispatch }, userId) {
      return axios
        .delete(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users?userId=${userId}`)
        .then(dispatch("loadAllUsers"))
        .catch(e => {
          console.log("deletion failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", { errorMessage: "Löschen fehlgeschlagen.", e: e });
        });
    },
    async deleteUsersWithCancelledMembership({ commit, dispatch }, [startDate, endDate]) {
      let date1 = startDate || new Date("1970-01-01").toISOString().slice(0, 10);
      let date2 = endDate || new Date().toISOString().slice(0, 10);
      const query = `${date1 ? 'startDate='+date1 : ''}&${date2 ? 'endDate='+date2 : ''}`;
      return axios
        .delete(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/membershipCancelled?${query}`)
        .then(dispatch("loadAllUsers"))
        .catch(e => {
          console.log("deletion failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", { errorMessage: "Löschen fehlgeschlagen.", e: e });
        });
    },
    async saveCourseDetails({ commit }, courseDetails) {
      commit("setLoadingYes");
      let course = courseDetails;
      return axios
        .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/`, course)
        .catch(e => {
          console.log("Updating course failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", { errorMessage: "Aktion fehlgeschlagen.", e: e });
        })
        .then(() => {
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/all/${this.state.adminArea.date}`
          );
        })
        .then(response => {
          commit("setCoursesOfDay", response.data);
          commit("setLoadingNo");
        });
    },
    async saveUserDetails({ commit }, userDetails) {
      commit("setLoadingYes");
      if (userDetails.id) {
        return axios
          .post(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/update`,
            userDetails
          )
          .catch(e => {
            console.log("Updating user failed");
            console.log(e);
            commit("setLoadingNo");
            commit("setError", {
              errorMessage: "Ändern fehlgeschlagen.",
              e: e
            });
          })
          .then(() => {
            return axios.get(
              `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users`
            );
          })
          .then(response => {
            commit("setAdminAreaAllUsers", response.data);
            commit("setLoadingNo");
          });
      } else {
        return axios
          .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/create`, {
            newUser: userDetails
          })
          .catch(e => {
            console.log("Updating creation failed");
            console.log(e);
            commit("setLoadingNo");
            commit("setError", {
              errorMessage: "Anlegen des Benutzers fehlgeschlagen.",
              e: e
            });
          })
          .then(() => {
            return axios.get(
              `${process.env.VUE_APP_DT_SERVICE_HOST}/api/users`
            );
          })
          .then(response => {
            commit("setAdminAreaAllUsers", response.data);
            commit("setLoadingNo");
          });
      }
    },
    async saveRegistrationDetails({ commit }, registrationDetails) {
      commit("setLoadingYes");
      if (registrationDetails.trainingId) {
        return axios
          .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings/update`, {
            registrationData: registrationDetails
          })
          .catch(e => {
            console.log("Updating training failed");
            console.log(e);
            commit("setLoadingNo");
            commit("setError", {
              errorMessage: "Ändern fehlgeschlagen.",
              e: e
            });
          })
          .then(() => {
            this.dispatch("updateAdminArea");
          });
      }
    },
    async createRegistration({ commit }, registrationDetails) {
      commit("setLoadingYes");
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings`,
          registrationDetails
        )
        .catch(e => {
          console.log("Updating creation failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: `Anlegen des Benutzers fehlgeschlagen.`,
            e: e
          });
        })
        .then(() => {
          this.dispatch("setDateForAdministration", this.state.adminArea.date);
        });
    },
    async deleteCourse({ commit }, courseDetails) {
      commit("setLoadingYes");
      return axios
        .delete(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/${courseDetails.id}`
        )
        .catch(e => {
          console.log("deleting course failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", { errorMessage: "Löschen fehlgeschlagen.", e: e });
        })
        .then(() => {
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/all/${this.state.adminArea.date}`
          );
        })
        .then(response => {
          commit("setCoursesOfDay", response.data);
          commit("setLoadingNo");
        });
    },
    async deleteRegistration({ commit }, registrationId) {
      commit("setLoadingYes");
      return axios
        .delete(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings/${registrationId}`
        )
        .catch(e => {
          console.log("deleting training failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", { errorMessage: "Löschen fehlgeschlagen.", e: e });
        })
        .then(() => {
          return axios.get(
            `${process.env.VUE_APP_DT_SERVICE_HOST}/api/courses/participants/${this.state.adminArea.date}`
          );
        })
        .then(response => {
          commit("setParticipantList", response.data);
          commit("setLoadingNo");
        });
    },
    async loadOrganisationSettings({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/organisations/`)
        .then(res => {
          return res.data;
        })
        .catch(e => {
          if (e.response.status != 404) {
            console.log("Updating Organisation Settings failed");
            console.log(e);
            commit("setLoadingNo");
            commit("setError", {
              errorMessage: "Laden der Einstellungen fehlgeschlagen.",
              e: e
            });
          }
        });
    },
    async saveAllOrganisationSettings({ commit }, values) {
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/organisations/`,
          values
        )
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("Updating Organisation Settings failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Änderung fehlgeschlagen.",
            e: e
          });
        });
    },
    async saveOrganisationSettings({ commit }, [key, value]) {
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/organisations/${key}`,
          { [key]: value }
        )
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("Updating Organisation Settings failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Änderung fehlgeschlagen.",
            e: e
          });
        });
    },
    async getExistingGroups({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/users/groups/used`)
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("getExistingGroups failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Laden fehlgeschlagen.",
            e: e
          });
        });
    },
    async getExistingBadges({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/badges`)
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("getExistingBadgesfailed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Laden fehlgeschlagen.",
            e: e
          });
        });
    },
    async saveBadge({ commit }, badgeObject) {
      return axios
        .post(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/badges`, badgeObject)
        .then(() => {
          return true;
        })
        .catch(e => {
          console.log("saveBadgefailed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Speichern fehlgeschlagen.",
            e: e
          });
          return false;
        });
    },
    async deleteBadge({ commit }, badgeId) {
      return axios
        .delete(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/badges/${badgeId}`)
        .then(() => {
          return true;
        })
        .catch(e => {
          console.log("delete badge failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Löschen fehlgeschlagen.",
            e: e
          });
          return false;
        });
    },
    async getExistingBadgeAchievements({ commit }) {
      return axios
        .get(`${process.env.VUE_APP_DT_SERVICE_HOST}/api/badgeAchievements/`)
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("getExistingBadgeAchievements failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Laden fehlgeschlagen.",
            e: e
          });
        });
    },
    async getExistingBadgeAchievementsForUser({ commit }, userId) {
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/badgeAchievements/forUser/${userId}`
        )
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("getExistingBadgeAchievementsForUserfailed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Laden fehlgeschlagen.",
            e: e
          });
        });
    },
    async informAboutReadyToCollectBadge({ commit }, achievementId) {
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/badgeAchievements/informAboutReadyToCollectBadge/${achievementId}`
        )
        .then(() => {
          return true;
        })
        .catch(e => {
          console.log("informAboutReadyToCollectBadge failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Senden fehlgeschlagen.",
            e: e
          });
          return false;
        });
    },
    async informAboutDeliveredBadge({ commit }, achievementId) {
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/badgeAchievements/informAboutDeliveredBadge/${achievementId}`
        )
        .then(() => {
          return true;
        })
        .catch(e => {
          console.log("informAboutDeliveredBadge failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Senden fehlgeschlagen.",
            e: e
          });
          return false;
        });
    },
    async saveAchievement({ commit }, achievmentObject) {
      return axios
        .post(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/badgeAchievements`,
          achievmentObject
        )
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("saveAchievmentfailed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Laden fehlgeschlagen.",
            e: e
          });
          return false;
        });
    },
    async deleteAchievement({ commit }, achievementId) {
      return axios
        .delete(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/badgeAchievements/${achievementId}`
        )
        .then(() => {
          return true;
        })
        .catch(e => {
          console.log("deleteAchievementfailed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Löschen fehlgeschlagen.",
            e: e
          });
          return false;
        });
    },
    async getParticipationsForUser({ commit }, { userId, fromDate, toDate }) {
      let query = "";
      if (fromDate || toDate) {
        query += "?";
        if (fromDate) {
          query += `fromDate=${fromDate}`;
        }
        if (fromDate && toDate) {
          query += "&";
        }
        if (toDate) {
          query += `toDate=${toDate}`;
        }
      }
      return axios
        .get(
          `${process.env.VUE_APP_DT_SERVICE_HOST}/api/trainings/userParticipated/${userId}+${query}`
        )
        .then(res => {
          return res.data;
        })
        .catch(e => {
          console.log("trainings/userParticipated/:userId failed");
          console.log(e);
          commit("setLoadingNo");
          commit("setError", {
            errorMessage: "Laden fehlgeschlagen.",
            e: e
          });
          return [];
        });
    },
    async setAdminAreaCourseDetails({ commit }, courseDetails) {
      commit("setAdminAreaCourseDetails", courseDetails);
    },
    async setAdminAreaRegistrationDetails({ commit }, courseDetails) {
      commit("setAdminAreaRegistrationDetails", courseDetails);
    },
    async setAdminAreaUserDetails({ commit }, userDetails) {
      commit("setAdminAreaUserDetails", userDetails);
    },
    setLoadingCardPairingBtnOn() {
      this.commit("setLoadingCardPairingBtn", true);
    },
    setLoadingCardPairingBtnOff() {
      this.commit("setLoadingCardPairingBtn", false);
    },
    enableAdminAreaComponentVisibility({ commit }, visibleComponent) {
      commit("resetAdminAreaComponentsVisibility");
      commit("enableAdminAreaComponent", visibleComponent);
    }
  },
  modules: {}
});
