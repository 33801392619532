<template>
  <v-container>
    <v-card class="mx-auto" color="blue-grey lighten-5" max-width="600">
      <v-card-title>
        <v-icon large left>
          mdi-account-circle
        </v-icon>
        <span class="title font-weight-light">Angemeldete Person</span>
      </v-card-title>

      <v-card-text class="headline font-weight-bold">
        {{ User.firstName }} {{ User.name }}
      </v-card-text>

      <v-card-actions>
        <v-container>
          <v-expansion-panels
            :multiple="componentVisibility.extendedUserData"
            :value="[0, 0]"
          >
            <v-expansion-panel
              @change="componentVisibility.extendedUserData = true"
            >
              <v-expansion-panel-header>Benutzerdaten</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Geburtsdatum"
                      disabled
                      filled
                      append-icon="mdi-cake-variant"
                      :value="User.birthdate || 'unbekannt'"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Code"
                      disabled
                      append-icon="mdi-check-circle-outline"
                      filled
                      :value="User.token"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Mitglied bei"
                      disabled
                      filled
                      :value="User.memberOfOrganisation"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field
                      label="Mitglieds-Nr."
                      disabled
                      filled
                      :value="User.membershipNr || 'noch nicht im System'"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field
                      type="tel"
                      label="Telefonnummer"
                      outlined
                      v-model="User.phoneNumber"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field label="E-Mail" outlined v-model="User.email">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-btn
                      x-large
                      color="blue lighten-3"
                      block
                      v-bind:loading="isLoading"
                      @click="setEmail([User.email, User.phoneNumber])"
                    >
                      speichern
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row>
                  Zur Anmeldung ist eine E-Mail-Adresse erforderlich!
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                >Bestandene Abzeichen<v-icon
                  v-if="hintNewAchievement"
                  color="red"
                  >mdi-alert</v-icon
                ></v-expansion-panel-header
              >
              <v-expansion-panel-content>
                <v-list :loading="pendingRequestExistingAchievements">
                  <v-list-item-group>
                    <v-list-item v-for="(item, i) in achievements" :key="i">
                      <v-list-item-icon>
                        <v-avatar size="56">
                          <img
                            v-if="item.badge.imageUrl"
                            alt="image of achievement"
                            :src="item.badge.imageUrl"
                          />
                        </v-avatar>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title v-text="item.badge.name">
                        </v-list-item-title>
                        <v-list-item-title v-if="!item.badgeDelivered">
                          NEU
                          <v-icon color="red">mdi-alert</v-icon>
                          Bitte beim nächsten Training abholen.
                        </v-list-item-title>
                        <v-list-item-title v-if="item.registrationId">
                          RegNr.: {{ item.registrationId }}
                        </v-list-item-title>
                        Prüfungsdatum: {{ item.achievementDate }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PersonDetails",

  data() {
    return {
      achievements: [],
      pendingRequestExistingAchievements: false,
      hintNewAchievement: false
    };
  },

  methods: {
    ...mapActions(["setEmail", "getExistingBadgeAchievementsForUser"]),
    async requestExistingBadgeAchievements() {
      this.pendingRequestExistingAchievements = true;
      this.achievements = await this.getExistingBadgeAchievementsForUser(
        this.User.id
      );
      this.hintNewAchievement = this.achievements.find(
        e => e.badgeIssued && !e.badgeDelivered
      );
      this.pendingRequestExistingAchievements = false;
    }
  },

  mounted: function() {
    this.requestExistingBadgeAchievements();
  },

  watch: {
    User() {
      this.requestExistingBadgeAchievements();
    }
  },

  computed: {
    Legende() {
      return this.$store.state.Legende;
    },
    token() {
      return this.$store.state.token;
    },
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      }
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    Training: {
      get() {
        return this.$store.state.training;
      }
    },
    Course: {
      get() {
        return this.$store.state.course;
      }
    },
    emailProvided: {
      get() {
        return this.$store.state.email;
      }
    }
  }
};
</script>
