<template>
  <v-container>
    <v-card class="mx-auto" color="green lighten-3" max-width="600">
      <v-card-title>
        <v-icon large left>
          mdi-information
        </v-icon>
        <span class="title font-weight-light">Teilnahmebedingungen</span>
        <v-icon v-if="termsChecked" color="green">mdi-check</v-icon>
        <v-spacer></v-spacer>
        <v-btn text v-if="termsChecked" @click="showTerms = !showTerms">
          anzeigen<v-icon>{{
            showTerms ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-expand-transition>
        <div v-show="showTerms">
          <v-card-text v-html="terms ? terms.termText : null">
            {{ terms ? terms.termText : "" }}
          </v-card-text>

          <v-card-actions>
            <v-icon v-if="!termsChecked" color="red">mdi-alert</v-icon>
            <v-checkbox
              v-model="termsChecked"
              label="Ich habe die Teilnahmebedingungen gelesen und akzeptiere sie."
              @change="setAcceptTerms(termsChecked)"
            ></v-checkbox>
            <v-btn v-if="termsChecked" @click="showTerms = false"
              >ausblenden</v-btn
            >
          </v-card-actions>
        </div>
      </v-expand-transition>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TermsCard",

  data() {
    return {
      showTerms: false
    };
  },

  methods: {
    ...mapActions(["setAcceptTerms"])
  },

  mounted: function() {
    this.showTerms = !this.termsChecked;
  },

  computed: {
    Legende() {
      return this.$store.state.Legende;
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    terms: {
      get() {
        return this.$store.state.terms;
      }
    },
    termsChecked: {
      get() {
        return this.$store.state.termsChecked;
      },
      set(value) {
        this.$store.commit("setTermsChecked", value);
      }
    }
  }
};
</script>
