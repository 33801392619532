<template>
  <v-container>
    <v-card
      v-if="componentVisibility.registrationDetails && emailProvided"
      class="mx-auto"
      :color="Legende[userStatus][1]"
      max-width="600"
    >
      <v-card-title>
        <v-icon large left>
          mdi-swim
        </v-icon>
        <span class="title font-weight-light"
          >Anmeldestatus für
          {{
            new Date(course.date).toLocaleDateString("de-DE", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric"
            })
          }}
        </span>
        <v-card-text class="headline" v-if="course.laneDescription">
          {{ course.laneDescription }}
        </v-card-text>
      </v-card-title>

      <v-card-actions>
        <v-card-text class="headline font-weight-bold">
          {{ User.firstName }} {{ User.name }} {{ Legende[userStatus][0] }}
        </v-card-text>

        <v-spacer></v-spacer>

        <v-btn text @click="showDetails = !showDetails">
          Alle Infos<v-icon>{{
            showDetails ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="showDetails">
          <v-divider></v-divider>

          <v-card-text class="headline" v-if="showDetails">
            Trainingsgruppe: {{ course.lane }}
          </v-card-text>
          <v-card-text
            v-if="course.infotext && showDetails"
            class="font-weight-bold"
          >
            Info: {{ course.infotext }}
          </v-card-text>

          <v-card-actions v-if="showDetails">
            <v-container>
              <h4 v-if="course.date">Trainingstermin:</h4>
              <p v-if="course.date">
                {{
                  new Date(course.date).toLocaleDateString("de-DE", {
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric"
                  })
                }}
              </p>
              <h4 v-if="course.occurenceTime">
                {{ course.occurenceTimeText }}
              </h4>
              <p v-if="course.occurenceTime">
                {{ course.occurenceTime.slice(0, 5) }} Uhr
              </p>
              <h4 v-if="course.startTime">{{ course.startTimeText }}</h4>
              <p v-if="course.startTime">
                {{ course.startTime.slice(0, 5) }} Uhr
              </p>
              <h4 v-if="course.endTime">{{ course.endTimeText }}</h4>
              <p v-if="course.endTime">{{ course.endTime.slice(0, 5) }} Uhr</p>
              <h4>
                Trainingsplätze belegt{{
                  course.registeredUsers >= course.maxParticipants
                    ? " (Warteliste verfügbar)"
                    : ""
                }}:
              </h4>
              <p>
                {{ course.registeredUsers }} von insgesamt
                {{ course.maxParticipants }}
              </p>
              <h4 v-if="userStatus == 3">
                Wartelistenplatz:
              </h4>
              <p v-if="userStatus == 3">{{ course.waitingListPlace }}</p>
            </v-container>
          </v-card-actions>
        </div>
      </v-expand-transition>
      <v-card-actions>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-btn
              x-large
              block
              :color="Legende[userStatus][3]"
              :disabled="userStatus > 3"
              v-bind:loading="isLoading"
              @click="
                userStatus == 1 ? register(course.id) : unregister(course.id)
              "
            >
              {{ Legende[userStatus][2] }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "RegistrationDetails",

  methods: {
    ...mapActions(["register", "unregister"])
  },

  props: ["course"],

  data() {
    return {
      showDetails: false
    };
  },

  computed: {
    userStatus() {
      if (!this.course.registrationPossible) return 5; // registrierung nicht möglich
      if (this.course.isRegistered) {
        if (this.course.waiting) return 3; // auf Warteliste
        return 2; // ist angemeldet
      }
      return 1; // nicht angemeldet, Registrierung möglich
    },
    Legende() {
      return this.$store.state.Legende;
    },
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      }
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    emailProvided: {
      get() {
        return this.$store.state.email;
      }
    }
  }
};
</script>
