<template>
  <v-card class="mx-auto" color="light-blue" dark max-width="600">
    <v-card-title>
      <v-icon large left>
        mdi-swim
      </v-icon>
      <span class="title font-weight-light"
        >Anmeldung zum nächsten Schwimmtraining</span
      >
    </v-card-title>

    <v-card-text class="headline font-weight-bold">
      Sie haben für sich / Ihr Kind per Post oder E-Mail einen Code erhalten.
      Nutzen Sie diesen Code für die Anmeldung.
    </v-card-text>

    <v-card-actions>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="6">
            <v-text-field
              label="Code"
              outlined
              v-model="tokenInput"
              @keydown.enter="loadUser([tokenInput, rememberCode])"
              v-bind:error-messages="loginFailed"
            ></v-text-field>
          </v-col>

          <v-col cols="12" sm="12" md="2">
            <v-checkbox v-model="rememberCode" label="Code merken">
            </v-checkbox>
          </v-col>

          <v-col cols="12" sm="12" md="4">
            <v-btn
              x-large
              color="primary"
              v-bind:loading="isLoading"
              @click="loadUser([tokenInput, rememberCode])"
            >
              bestätigen
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "TokenForm",

  data() {
    return {
      rememberCode: false
    };
  },

  methods: {
    ...mapActions(["loadUser"])
  },

  beforeMount: function() {
    let userID = localStorage.getItem("digitrain-user");
    if (userID) {
      this.loadUser([userID, true]);
    }
  },

  computed: {
    tokenInput: {
      get() {
        return this.$store.state.token;
      },
      set(value) {
        this.$store.commit("setToken", value);
      }
    },
    isLoading: {
      get() {
        return this.$store.state.isLoading;
      }
    },
    loginFailed: {
      get() {
        return this.$store.state.loginFailed;
      }
    }
  }
};
</script>
