<template>
  <v-app>
    <v-app>
      <v-navigation-drawer
        v-if="Token"
        v-model="drawer"
        mobile-breakpoint="sm"
        :expand-on-hover="
          $vuetify.breakpoint.lg ||
            $vuetify.breakpoint.md ||
            $vuetify.breakpoint.xl
        "
        app
        clipped
        :temporary="
          !$vuetify.breakpoint.lg &&
            !$vuetify.breakpoint.md &&
            !$vuetify.breakpoint.xl
        "
      >
        <v-list>
          <v-list-item two-line class="px-3">
            <v-list-item-avatar>
              <v-icon large left>
                mdi-account-circle
              </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title class="title"
                >{{ User.firstName }} {{ User.name }}</v-list-item-title
              >
              <v-list-item-subtitle>{{ User.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <v-list nav dense>
          <v-list-item-group active-class="red--text text--accent-4">
            <v-list-item to="/">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>

        <AdminAreaMenu v-if="componentVisibility.adminArea" />
      </v-navigation-drawer>

      <v-app-bar app color="red" dark clipped-left>
        <v-app-bar-nav-icon
          v-if="componentVisibility.adminArea"
          @click="drawer = !drawer"
        ></v-app-bar-nav-icon>

        <div class="d-flex align-center">
          <v-img
            alt="DLRG Logo"
            class="shrink mr-2"
            contain
            src="https://www.dlrg.de/global/layout/2019/images/DLRG-Logo.svg"
            width="160"
          />
          <h1 class="yellow--text">
            {{
              ($vuetify.breakpoint.lg ||
              $vuetify.breakpoint.md ||
              $vuetify.breakpoint.xl) &&
              User.organisation
                ? "| " + User.organisation
                : ""
            }}
          </h1>
        </div>

        <v-spacer></v-spacer>

        <v-btn
          v-if="componentVisibility.adminArea && autoUpdateVisible"
          icon
          dark
          @click="changeAutoUpdate"
        >
          <v-icon>{{ autoUpdate ? "mdi-update" : "mdi-reload" }}</v-icon>
        </v-btn>
        <v-btn v-if="Token ? true : false" @click="logout" href="/">
          <v-icon left dark>
            mdi-logout
          </v-icon>
          logout
        </v-btn>
      </v-app-bar>

      <v-main :class="drawer ? 'pl-16' : 'pl-0'">
        <TokenForm v-if="!User || !User.name" />
        <router-view />
      </v-main>
      <v-snackbar
        v-model="showError.visible"
        color="error"
        centered
        multi-line
        timeout="-1"
      >
        {{ showError.message }}

        <template v-slot:action="{ attrs }">
          <v-btn color="black" text v-bind="attrs" @click="closeError">
            OK
          </v-btn>
        </template>
      </v-snackbar>
    </v-app>
  </v-app>
</template>

<script>
// @ is an alias to /src
import { mapActions } from "vuex";
import TokenForm from "@/components/TokenForm.vue";
import AdminAreaMenu from "@/components/AdminAreaMenu.vue";

export default {
  name: "App",

  components: {
    TokenForm,
    AdminAreaMenu
  },

  data() {
    return {
      //
      drawer: false,
      autoUpdate: false,
      autoUpdateInterval: null,
      autoUpdateVisible: false
    };
  },

  methods: {
    ...mapActions(["updateAdminArea"]),
    logout() {
      localStorage.removeItem("digitrain-user");
    },
    closeError() {
      this.$store.commit("approveError");
    },
    changeAutoUpdate() {
      this.autoUpdate = !this.autoUpdate;
      if (this.autoUpdate) {
        this.autoUpdateInterval = setInterval(this.updateAdminArea, 5000);
      } else {
        clearInterval(this.autoUpdateInterval);
      }
    },
    setDrawerEqualAdminStatus() {
      this.drawer = this.componentVisibility.adminArea;
    }
  },

  watch: {
    $route(to) {
      this.autoUpdateVisible = to.params.component == "registrations";
    },
    componentVisibilityAdminArea: function(newVal, oldVal) {
      if (newVal != oldVal) this.setDrawerEqualAdminStatus();
    }
  },

  computed: {
    showError: {
      get() {
        return this.$store.state.showError;
      }
    },
    Token: {
      get() {
        return this.$store.state.user.token;
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    componentVisibilityAdminArea: {
      get() {
        return this.$store.state.componentVisibility.adminArea;
      }
    }
  }
};
</script>
