<template>
  <div class="swimming">
    <RegisterForm v-if="User && User.organisation" />
  </div>
</template>

<script>
// @ is an alias to /src
import RegisterForm from "@/components/RegisterForm.vue";

export default {
  name: "home",

  components: {
    RegisterForm
  },

  data() {
    return {
      dialog: true,
      notifications: false,
      sound: true,
      widgets: false
    };
  },

  computed: {
    token() {
      return this.$store.state.token;
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    Training: {
      get() {
        return this.$store.state.training;
      }
    }
  }
};
</script>
