<template>
  <v-container>
    <v-card class="mx-auto" color="yellow" max-width="600">
      <v-card-title>
        <v-icon large left>
          mdi-information
        </v-icon>
        <span class="title font-weight-light">Information</span>
      </v-card-title>

      <v-card-text v-if="User.status == 6" class="headline font-weight-bold">
        Aktuell kein Training verfügbar
      </v-card-text>

      <v-card-text v-if="User.prepayedOption" class="headline font-weight-bold">
        Freitermine verbleibend: {{ User.prepayedTimesLeft }}
      </v-card-text>

      <v-card-text v-if="User.infoMessage" class="headline font-weight-bold">
        Nachricht:
        <v-textarea v-model="User.infoMessage" outlined auto-grow disabled>
        </v-textarea>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "InfoCard",

  methods: {
    ...mapActions([])
  },

  computed: {
    Legende() {
      return this.$store.state.Legende;
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    },
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    }
  }
};
</script>
