<template>
  <v-container>
    <PersonDetails v-if="componentVisibility.personDetails" />
    <DppTermsCard v-if="componentVisibility.dppTermsCard" />
    <TermsCard v-if="componentVisibility.termsCard" />
    <InfoCard v-if="componentVisibility.infoCard" />
    <RegistrationDetails
      v-for="course in Courses"
      v-bind:key="course.id"
      v-bind:course="course"
    />
  </v-container>
</template>

<script>
import PersonDetails from "@/components/PersonDetails.vue";
import RegistrationDetails from "@/components/RegistrationDetails.vue";
import TermsCard from "@/components/TermsCard.vue";
import DppTermsCard from "@/components/DppTermsCard.vue";
import InfoCard from "@/components/InfoCard.vue";

import { mapActions } from "vuex";

export default {
  name: "RegisterForm",

  components: {
    PersonDetails,
    RegistrationDetails,
    TermsCard,
    DppTermsCard,
    InfoCard
  },

  methods: {
    ...mapActions(["loadCourses"])
  },

  mounted() {
    this.loadCourses();
  },
  computed: {
    User: {
      get() {
        return this.$store.state.user;
      },
      set(value) {
        this.$store.commit("setUser", value);
      }
    },
    Courses: {
      get() {
        return this.$store.state.course;
      }
    },
    componentVisibility: {
      get() {
        return this.$store.state.componentVisibility;
      },
      set(value) {
        this.$store.commit("setComponentVisibility", value);
      }
    }
  }
};
</script>
